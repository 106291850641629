import React from "react";
import Layout from "../components/layout";

const MentionsLegalesPage = () => {
  return (
    <Layout>
      <h1 className={"text-4xl my-12"}>Mentions légales</h1>
      <div className="bg-white border px-8 py-6 prose mx-auto">
        <div className={""}>
          <Subtitle>Hébergement</Subtitle>
          <p>
            Nom de l'hébergeur : Netlify<br />
            Adresse de l'hébergeur : Netlify, Inc., located at 512 2nd Street, Suite 200 San Francisco, CA 94107, USA</p>
          Site web : <a href="https://www.netlify.com">www.netlify.com</a>
          <Subtitle>Propriété intellectuelle</Subtitle>
          <p>
            Le contenu du site web (textes, images, vidéos, etc.) est protégé par le droit d'auteur et par d'autres droits de propriété intellectuelle. Toute reproduction, diffusion ou modification non autorisée du contenu du site web est interdite.
            Vous pouvez demander l'autorisation de reproduire ou de diffuser le contenu du site web en contactant le directeur de la publication.
          </p>
          <Subtitle>Responsabilité</Subtitle>
          <p>Vous utilisez le site web à vos risques et périls. L'éditeur ne peut être tenu responsable des dommages directs ou indirects résultant de l'utilisation du site web.
            L'éditeur s'engage à prendre toutes les mesures raisonnables pour assurer la qualité et la sécurité du site web, mais il ne peut pas garantir que le site web soit exempt de virus ou d'autres logiciels malveillants.</p>
        <Subtitle>Loi applicable et juridiction compétente</Subtitle>
          <p>Les présentes mentions légales sont régies par la loi française. Tout litige relatif aux présentes mentions légales sera soumis à la juridiction exclusive des tribunaux compétents de Paris.</p>
        </div>

      </div>
    </Layout>
  );
};

const Subtitle = ({ children }) => (
  <h2 className={"text-2xl mt-8 font-semibold mb-4"}>{children}</h2>
);

export default MentionsLegalesPage;